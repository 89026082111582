<template>
	<div class="info container-secondary-main">
		<h4>{{data.title}}</h4>
		<p class="detail-time">{{data.add_time}}<!-- &nbsp;&nbsp;&nbsp;&nbsp;润农采购部<i class="el-icon-view"></i><span>11111</span> --></p>
		<div class="info-content">
			<div v-html="data.info"></div>
			<!-- <p>一、项目基本情况  项目编号：RNC2024-012  项目名称：山东润农农业发展有限公司大宗物资采购服务项目  预算金额：0</p> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "NewsInfo",
		props: {
			data: {
				type: Object, // Prop的类型
				required: true // 该Prop是必需的
			}
		},
		data() {
			return {}
		},

	}
</script>

<style lang="less" scoped>
	.info {
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		padding: 2.75rem 1rem;
		text-align: center;

		.detail-time {
			font-size: 0.875rem;
			color: #333333;
			margin-top: 1.25rem;
			border-bottom: 1px solid #f2f2f2;
			padding: 0 4.1875rem 1rem;

			i {
				margin-left: 2rem;
			}

			span {
				color: #D70C19;
			}
		}

		.info-content {
			text-align: left;
			font-size: 0.875rem;
			color: #333333;
			margin-top: 3rem;
			padding: 0 4.1875rem;
		}
		.info-content /deep/ img{
			max-width: 100%;
		}
	}
</style>