<template>
	<div class="news-detail">
		<NewsHeader :data="data"></NewsHeader>
		<NewsInfo :data="info"></NewsInfo>
	</div>
</template>

<script>
	import NewsHeader from '@/components/news/NewsHeader.vue';
	import NewsInfo from '@/components/news/NewsInfo.vue';
	import { 
		getArticleInfo,
	} from '@/api/store.js'
	import {
			mapState
	} from 'vuex'
	export default {
		name: "enteringHuifa",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		components:{
			NewsHeader,
			NewsInfo
		},
		data() {
			return {
				id:this.$route.params.id,
				type:'',
				data:{
					name:'惠发动态',
					name2:'详情',
					url:'/newsCenter/index/'
				},
				info:{}
				
			}
		},
		created() {
			this.data.url = this.data.url + this.$route.query.type
			this.getArticleInfoFun()
			this.data.name=this.lang.huifa_dynamics
			this.data.name2=this.lang.huifa_detail
		},
		methods:{
			path(routePath){
				 this.$router.push(routePath); 
			},
			getArticleInfoFun(){
				// 获取分类
                let data = {
                    id: this.id,
                }
				getArticleInfo(data).then(res=>{
					this.info=res.data.article_info
					// this.typeList = [...this.typeList, ...res.data]
				})
			},
		}
		
	}
</script>

<style lang='less'>
	.news-detail{
		background: linear-gradient(to bottom, #FFE0E0, #F2F2F2),#F2F2F2;
		background-repeat: no-repeat;
		background-size: 100% 25rem;
		padding-bottom: 8rem;
	}
	/* 响应式布局 */
	@media (max-width: 1220px) {
		.news-detail {
		    padding:0 1rem 8rem;
		}
	}
</style>